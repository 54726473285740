import React from "react";

import sweepSerfCover from './screenshots/sweepSerfCover.png'
import SS0 from './screenshots/SS0.png'
import SS1 from './screenshots/SS1.png'
import SS2 from './screenshots/SS2.png'


const SweepSerfAbout = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-lg-4 col-md-4">
                    <img className="contained-img rounded" src={sweepSerfCover} alt="Cover Art"></img>
                </div>
                <div className="col p-3">
                    <dl className="row text-center">
                        <dt className="col-4">Title</dt>
                        <dd className="col-8 sweet-font">SweepSerf</dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Description</dt>
                        <dd className="col-8">
                            SweepSerf is a 2D platformer where the goal is to get as far down as possible while solving minesweeper-like puzzles.
                        </dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Release Date</dt>
                        <dd className="col-8">Ongoing</dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Link</dt>
                        <dd className="col-8">
                            <a rel="noreferrer" href="/sweepserf">
                                Play Game
                            </a>
                        </dd>
                    </dl>
                </div>
            </div>
            <dl className="row mt-0 mt-sm-1 mt-md-2 mt-lg-3">
                <dt className="col-sm-3 fs-3 text-center">Notes</dt>
                <dd className="col-sm-9">The latest game I've been working on</dd>

                <dt className="col-sm-3"></dt>
                <dd className="col-sm-9">
                    <p>
                        I initially came up with the idea when I noticed how popular games like
                        <b>"Jump King"</b>, <b>"Pogostuck"</b>, and <b>"Getting Over It with Bennett Foddy"</b> were
                        among streamers. Then I thought <i>"I can make Jump King"</i> and so I just took that
                        game and flipped it on it's head.
                    </p>
                    <p>
                        What's the opposite of Jump? Going down. What's the opposite of a King? A Serf.
                        When it came to increasing difficulty by digging down, the idea to use
                        minesweeper logic as a base just kinda came to me and thus, SweepSerf was born!
                    </p>
                    <p>
                        As I began developing the game I realized that since the level was randomly
                        generated players could become trapped with no other way to progress besides
                        guessing and hoping for the best. At this point I could either write an algorithm
                        to generate solvable levels - which felt boring to me - or add items to empower
                        the player to progress down without compromising randomness.
                    </p>
                </dd>
            </dl>
            <div className="row bg-dark rounded">
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={SS0} alt="Screenshot 0"></img>
                </div>
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={SS1} alt="Screenshot 1"></img>
                </div>
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={SS2} alt="Screenshot 2"></img>
                </div>
            </div>
        </div>
    )
}

export default SweepSerfAbout
