import React, { useEffect } from "react";
// import { isMobile } from "react-device-detect"
import GameListElement from './GameListElement';
import { useWindowDimensions } from "../../global/WindowUtils";

import sweepserf_cover from './coverArt/sweepserf_cover_compact.png'

import './GameList.css';

const GameList = () => {
    const { compact } = useWindowDimensions();

    useEffect(() => {
        document.title = "Hungry Guy Games";
    }, []);

    return (
        <div className={"game page-container " + (compact ? "is-mobile" : "is-desktop")}>
            <GameListElement gameName="SweepSerf" gameLink="/sweepserf"
                imgSrc={sweepserf_cover} imgAltText="SweepSerf Cover Art" />
            {/*
            {isMobile &&
                <button className="add-button" style={{ display: "none" }} ref={addBtn}>
                    Add To Home Screen
                </button>
            }<GameListElement gameName="Assimilation Eater" gameLink="/assimilationeater"/>*/}
        </div>
    )
}

export default GameList
