import React from "react";

import assEater_cover from './screenshots/assEater_cover.png'
import AE0 from './screenshots/AE0.png'
import AE1 from './screenshots/AE1.png'

const AssEaterAbout = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-lg-4 col-md-4">
                    <img className="contained-img rounded" src={assEater_cover} alt="Cover Art"></img>
                </div>
                <div className="col p-3">
                    <dl className="row text-center">
                        <dt className="col-4">Title</dt>
                        <dd className="col-8 sweet-font">Ass Eater: The Revenge of Ouroboros</dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Description</dt>
                        <dd className="col-8">
                            A 2D puzzle game where you control a bug that can change its shape
                            based on the blocks it eats.
                        </dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Release Date</dt>
                        <dd className="col-8">Sep 02, 2020</dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Link</dt>
                        <dd className="col-8">
                            <a target="_blank" rel="noreferrer" href="https://spacebrogabe.itch.io/ass-eater-the-revenge-of-ouroboros">
                                Play Game
                            </a>
                        </dd>
                    </dl>
                </div>
            </div>
            <dl className="row mt-0 mt-sm-1 mt-md-2 mt-lg-3">
                <dt className="col-sm-3 fs-3 text-center">Notes</dt>
                <dd className="col-sm-9">A poorly titled name haha</dd>

                <dt className="col-sm-3"></dt>
                <dd className="col-sm-9">
                    <p>
                        This is the first game I've worked on in a Game Jam. The prompt was <b>"You are what you consume"</b>
                        and so I came up with the idea for a caterpillar
                        that grew in size on a grid by eating blocks and the goal of the game was to clear
                        a level by becoming a specific shape.
                    </p>
                    <p>
                        I was part of a team of about 5 and it was being developed on stream, hence
                        why we got a little carried away and gave it a joke title 😅
                    </p>
                    <p>
                        We developed the core game mechanics and were able to design a few levels
                        but didn't really build on the concept after the game jam. This is a game
                        I'd definitely want to revitalize at some point.
                    </p>
                </dd>
            </dl>
            <div className="row bg-dark rounded">
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={AE0} alt="Screenshot 0"></img>
                </div>
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={AE1} alt="Screenshot 1"></img>
                </div>
                {/*
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={AE0} alt="Screenshot 2"></img>
                </div>
                */}
            </div>
        </div>
    )
}

export default AssEaterAbout
