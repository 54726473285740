import React from 'react'

const PageNotFound = () => {
    return (
        <div className="container is-desktop">
            <div className="content-block full-width content-header">
                Page Not Found
            </div>
        </div>
    )
}

export default PageNotFound
