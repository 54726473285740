import React, { useEffect } from "react";
import { useWindowDimensions } from "../../global/WindowUtils";

import GamePage from "./GamePage";

const SweepSerf = () => {
    const { compact } = useWindowDimensions();

    useEffect(() => {
        document.title = "SweepSerf";
    }, []);

    return (
        <>
            <GamePage
                gameName="sweepserf"
                pixelate="true"
                width={compact ? 180 : 240}
                height={compact ? 320 : 180}
            ></GamePage>
        </>
    )
}

export default SweepSerf
