import React, { useEffect } from "react";
import { useWindowDimensions } from "../global/WindowUtils"

import UpdateElement from './updateElements/UpdateElement'

import PeepoZero from './updateElements/PeepoZero.png'
import Celebration from './updateElements/Celebration.png'

const Updates = () => {
    const { compact } = useWindowDimensions();

    useEffect(() => {
        document.title = "Updates";
    }, []);

    return (
        <div className="scrollable">
            <div className={"page-container justify-content-center " + (compact ? "is-mobile" : "is-desktop")}>
                <UpdateElement index="001" date="7/04/22" imgSrc={Celebration} imgAltText="Celebration"
                    title="SweepSerf is out!" 
                    text="The first playable version of SweepSerf is out. I would say right now it's in a playable demo state
                     but a lot of work that went into the game prior to the release was to make it easier to iterate on so expect
                     updates to the game to keep releasing 👀">
                </UpdateElement>
                <UpdateElement index="000" date="6/30/22" imgSrc={PeepoZero} imgAltText="Dark Magician Peepo"
                    title="The first update!"
                    text="The website is now live! It's still a bit in progress but my first game should be available to play soon™">
                </UpdateElement>
            </div>
        </div>
    )
}

export default Updates
