import { isMobile } from "react-device-detect"

import Navbar from "../navbar/Navbar.js"

const Footer = () => {
    return (isMobile ? (
        <div className="footer" style={{
            position: "absolute",
            bottom : 0,
            width : "100%",
            height : "44px",
            borderTop : "1px solid black",
            backgroundColor : "#111111"
        }}>
            <Navbar />
        </div>
    ) : <></>)
}

export default Footer