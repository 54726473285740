import React from 'react';
import { NavLink } from 'react-router-dom'
import { IoNewspaper, IoInformationCircleSharp, IoGameController } from "react-icons/io5";
import { useWindowDimensions } from "../WindowUtils";
import './Navbar.css';


const Navbar = () => {
    const { compact } = useWindowDimensions();

    return (
        <div className="container text-center">
            <div className="row">
                <div className="col">
                    <NavLink to="/updates" className="nav-element">
                        <button>{compact ? <IoNewspaper /> : 'Updates'}</button>
                    </NavLink >
                </div>
                <div className="col">
                    <NavLink to="/" className="nav-element">
                        <button>{compact ? <IoGameController /> : 'Games'}</button>
                    </NavLink >
                </div>
                <div className="col">
                    <NavLink to="/about" className="nav-element">
                        <button>{compact ? <IoInformationCircleSharp /> : 'About'}</button>
                    </NavLink >
                </div>
            </div>
        </div>
    )
}

export default Navbar
