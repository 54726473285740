import React, { useEffect, useState } from "react";
import { FaTwitter, FaInstagram, FaTwitch } from "react-icons/fa";
import { useWindowDimensions } from "../../global/WindowUtils";

import SweepSerfAbout from "./SweepSerfAbout";
import HomeGameAbout from "./HomeGameAbout";
import AssEaterAbout from "./AssEaterAbout";

import './About.css';

const About = () => {

    useEffect(() => {
        document.title = "About";
    }, []);

    const { compact } = useWindowDimensions();

    const [activeTab, setActiveTab] = useState("SweepSerf");

    const onPress = (newValue) => {
        setActiveTab(newValue);
    }

    return (
        <div className="scrollable">
            <div className={"page-container " + (compact ? "is-mobile" : "is-desktop")}>

                <div className="content-block p-1 p-sm-2 p-md-3 p-lg-4">

                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col socials-col bg-dark mb-1 mb-sm-2 mb-md-3 mb-lg-4 rounded">
                                <div className="row p-3">
                                    <div className="col">
                                        <a target="_blank" rel="noreferrer" href="https://twitter.com/HungryGuyGames"
                                            type="button" className="btn btn-outline-light long-button">
                                            {!compact && "@HungryGuyGames"}<FaTwitter />
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/hungryguygames/"
                                            type="button" className="btn btn-outline-light long-button">
                                            {!compact && "@HungryGuyGames"}<FaInstagram />
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a target="_blank" rel="noreferrer" href="https://www.twitch.tv/final_turn"
                                            type="button" className="btn btn-outline-light long-button">
                                            {!compact && "@Final_Turn"}<FaTwitch />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row px-2 py-3 sweet-font">
                            Welcome to my site! My name is <span className="text-primary">David</span> and I'm an aspiring game developer.
                            I want to make tons of games and put them on here for everyone to enjoy!
                        </div>
                        <div className="row px-2 pb-4 sweet-font">
                            Below are some tabs decicated to games I've worked on in the past.
                        </div>
                    </div>

                    <ul className="nav nav-tabs mb-3" id="myTab0" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link " + (activeTab === "SweepSerf" ? "active" : "")}
                                type="button"
                                role="tab"
                                onClick={() => { onPress("SweepSerf") }}
                            >
                                SweepSerf
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link " + (activeTab === "Home" ? "active" : "")}
                                type="button"
                                role="tab"
                                onClick={() => { onPress("Home") }}
                            >
                                HOME
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link " + (activeTab === "AE" ? "active" : "")}
                                type="button"
                                role="tab"
                                onClick={() => { onPress("AE") }}
                            >
                                "A Eater"
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className={"tab-pane fade " + (activeTab === "SweepSerf" ? "show active" : "")} role="tabpanel">
                            <SweepSerfAbout />
                        </div>
                        <div className={"tab-pane fade " + (activeTab === "Home" ? "show active" : "")} role="tabpanel">
                            <HomeGameAbout />
                        </div>
                        <div className={"tab-pane fade " + (activeTab === "AE" ? "show active" : "")} role="tabpanel">
                            <AssEaterAbout />
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default About
