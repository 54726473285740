import React from "react"
import "./App.css";

import Header from './components/global/header/Header'
import Footer from './components/global/footer/Footer'
import Updates from './components/pages/Updates'
import About from './components/pages/about/About'
import GameList from './components/pages/gameList/GameList'
import SweepSerf from './components/pages/gamePages/SweepSerf'
import RibbitRhythm from './components/pages/gamePages/RibbitRhythm'
import PageNotFound from "./components/PageNotFound";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
    <div className="root-div">
      <Header />
      <div className="page-root">
        <Routes>
          <Route path="/" exact element={<GameList />} />
          <Route path='/updates' element={<Updates />} />
          <Route path='/about' element={<About />} />
          <Route path='/sweepserf' element={<SweepSerf />} />
          <Route path='/ribbitrhythm' element={<RibbitRhythm />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
    </BrowserRouter>
  )
}

export default App