import React, { useEffect } from "react";

import GamePage from "./GamePage";

const RibbitRhythm = () => {

    useEffect(() => {
        document.title = "Ribbit Rhythm";
    }, []);

    return (
        <>
            <GamePage gameName="ribbitRhythm"></GamePage>
        </>
    )
}

export default RibbitRhythm
