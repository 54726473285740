import React, { useEffect, useContext, useState, useRef } from "react";
import { isMobile } from "react-device-detect"
import { Unity, useUnityContext } from "react-unity-webgl";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import CanvasWrapper from "./gamePageElements/CanvasWrapper";

import './GamePage.css';

const GamePage = ({ gameName, pixelate, width, height }) => {
    const [safeExit, setSafeExit] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const fullscreenHandle = useFullScreenHandle();

    const stretchGame = (!width || !height);

    const {
        unityProvider,
        isLoaded,
        loadingProgression,
        sendMessage,
        addEventListener,
        removeEventListener,
        unload
    } = useUnityContext({
        loaderUrl: `./builds/${gameName}/Build.loader.js`,
        dataUrl: `./builds/${gameName}/Build.data`,
        frameworkUrl: `./builds/${gameName}/Build.framework.js`,
        codeUrl: `./builds/${gameName}/Build.wasm`,
    });

    const { navigator } = useContext(NavigationContext);

    // When the component is mounted, we'll register some event listener.
    useEffect(() => {
        const onToggleFullScreen = (isAlreadyFullscreen) => {
            if (isAlreadyFullscreen === "False") {
                fullscreenHandle.enter();
            }
            else if (isAlreadyFullscreen === "True") {
                fullscreenHandle.exit();
            }
            else {
                console.log("Unhandled fullscreen request: " + isAlreadyFullscreen)
            }
        };

        const onRequestGameMetadata = () => {
            sendMessage("Web Interface", "ReceiveOnLoadRequest", isMobile ? "mobile" : "desktop");
        };

        addEventListener("ToggleFullscreen", onToggleFullScreen);
        addEventListener("RequestGameMetadata", onRequestGameMetadata);

        // I don't know why this works but gdi it supresses the error and thats good enough for me
        const closeGame = navigator.block(async (tx) => {
            // console.log("DEBUG: safeExit: " + safeExit);
            if (isLoaded && !safeExit) {
                // console.log("DEBUG: isloaded !safeExit");
                await unload();
                await setSafeExit(true);
                closeGame();
                tx.retry();
            } else {
                // console.log(`DEBUG: ${isLoaded} ${safeExit}`);
                closeGame();
                tx.retry();
            }
        });

        function fullscreenchanged(event) {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If there isn't one,
            // the value of the property is null.
            if (document.fullscreenElement) {
                sendMessage("Web Interface", "ReceiveFullscreenRequest", "true");
                setIsFullscreen(true);
            } else {
                sendMessage("Web Interface", "ReceiveFullscreenRequest", "false");
                setIsFullscreen(false);
            }
        }

        window.addEventListener('fullscreenchange', fullscreenchanged);

        function OnResize(event) {
            sendMessage("Web Interface", "ReceiveDimensionsChangeRequest");
        }

        window.addEventListener('resize', OnResize);

        // When the component is unmounted, we'll unregister the event listener.
        return function () {
            removeEventListener("ToggleFullscreen", onToggleFullScreen);
            removeEventListener("RequestGameMetadata", onRequestGameMetadata);
            window.removeEventListener('fullscreenchange', fullscreenchanged);
            window.removeEventListener('resize', OnResize);
            closeGame();
        };
    }, [
        addEventListener,
        removeEventListener, unityProvider, navigator, unload,
        isLoaded, sendMessage, safeExit, setSafeExit, fullscreenHandle, setIsFullscreen]);

    const loadingPercentage = Math.round(loadingProgression * 100);

    const canvasRef = useRef(null);

    if (canvasRef.current && !stretchGame) {
        canvasRef.current.setAttribute("width", width);
        canvasRef.current.setAttribute("height", height);
    }

    return (<div className={pixelate ? "pixelate" : ""}>
        <FullScreen handle={fullscreenHandle}>
            <CanvasWrapper ratio={stretchGame ? 0 : width / height} isFullscreen={isFullscreen}>
                {isLoaded === false && (
                    <div className="centered text-center">
                        <div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p>({loadingPercentage}%)</p>
                    </div>
                )}
                <Unity
                    matchWebGLToCanvasSize={stretchGame}
                    style={{ visibility: isLoaded ? "visible" : "hidden", width: "100%", height: "100%" }}
                    unityProvider={unityProvider}
                    ref={canvasRef}
                />
            </CanvasWrapper>
        </FullScreen>
    </div>)
}

export default GamePage