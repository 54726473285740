import React from "react";

import homeGame_cover from './screenshots/homeGame_cover.png'
import H0 from './screenshots/H0.png'
import H1 from './screenshots/H1.png'

const HomeGameAbout = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-lg-4 col-md-4">
                    <img className="contained-img rounded" src={homeGame_cover} alt="Cover Art"></img>
                </div>
                <div className="col p-3">
                    <dl className="row text-center">
                        <dt className="col-4">Title</dt>
                        <dd className="col-8 sweet-font">HOME</dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Description</dt>
                        <dd className="col-8">
                            HOME is a Foddian game that takes place in space.
                            The goal is to fling your spaceship back home using the
                            gravity of the planets along the way
                        </dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Release Date</dt>
                        <dd className="col-8">Oct 26, 2021</dd>
                    </dl>
                    <dl className="row text-center">
                        <dt className="col-4">Link</dt>
                        <dd className="col-8">
                            <a target="_blank" rel="noreferrer" href="https://finalturn.itch.io/home">
                                Play Game
                            </a>
                        </dd>
                    </dl>
                </div>
            </div>
            <dl className="row mt-0 mt-sm-1 mt-md-2 mt-lg-3">
                <dt className="col-sm-3 fs-3 text-center">Notes</dt>
                <dd className="col-sm-9">The fastest game I've made 🏃</dd>

                <dt className="col-sm-3"></dt>
                <dd className="col-sm-9">
                    <p>
                    Ludwig, a popular YouTube streamer, made a Game Jam looking for people to
                    create Foddian games. Unfortunately, I had already implemented a crude
                    demo of SweepSerf at that point so I couldn't enter with that idea,
                    so I just made a new one instead 😎
                    </p>
                    <p>
                    That's when I came up with HOME. I knew that in order for this game to stand out,
                    It needed to NOT be a 2D platformer so to facilitate this shift I had the game
                    take place in space.
                    </p>
                    <p>
                    Part of what makes designing Foddian games interesting is that they typically take
                    place on one large map where any mistake could make you lose progress and possibly
                    bring you all the way back to the beginning. However, since this game took place
                    in space it wasn't as straightforward as having the player go down.
                    </p>
                    <p>
                    Luckily there was a natural solution to this problem - arrange the planets in A
                    spiral shape. That way if the player missed a planet they would be drawn to the
                    center of the galaxy and could salvage progress by being caught by the inner spirals.
                    </p>
                </dd>
            </dl>
            <div className="row bg-dark rounded">
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={H0} alt="Screenshot 0"></img>
                </div>
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={H1} alt="Screenshot 1"></img>
                </div>
                {/*
                <div className="col-sm-12 col-lg-4 col-md-4 p-2">
                    <img className="contained-img rounded" src={AE0} alt="Screenshot 2"></img>
                </div>
                */}
            </div>
        </div>
    )
}

export default HomeGameAbout
