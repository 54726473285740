import { useWindowDimensions } from "../WindowUtils";
import Navbar from "../navbar/Navbar.js"
import Logo from './Logo.png'
// import AccountMenu from "./AccountMenu.js"
// import NavMenu from "./NavMenu";

import './Header.css';

const Header = () => {
    const { compact } = useWindowDimensions();
    return (
        <div className={"header-container " + (compact ? 'compact' : 'desktop')}>
            <div className="header-logo">
                <img src={Logo} alt="Logo" />
                {!compact ? <span>Hungry Guy Games</span> : <> </>}
            </div>
            {!compact ?
                <div className="header-navbar">
                    <Navbar />
                </div>
                : <> </>
            }
        </div>
    )
}

export default Header
