import React from 'react'
import { Link } from 'react-router-dom'

import './GameListElement.css'

// Assumptions: The parent component will set the .is-mobile or .is-desktop classes
const GameListElement = ({ gameName, gameLink, imgSrc, imgAltText }) => {
    return (
        <div className="item">
            <Link to={gameLink}>
                <div className="buffer">
                </div>
                <div className="cover-art">
                    {imgSrc &&
                        <img src={imgSrc} alt={imgAltText}>
                        </img>
                    }
                </div>
            </Link>
            <div className="game-title px-2" title={gameName}>
                {gameName}             
            </div>
        </div>
    )
}

export default GameListElement