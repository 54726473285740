import React from "react";
import { useWindowDimensions } from "../../../global/WindowUtils";
import './CanvasWrapper.css';


const CanvasWrapper = ({ children, ratio, isFullscreen }) => {
    const { width, height } = useWindowDimensions();

    // if ratio is 0 then that means parent wants content to stretch
    let padding = ratio !== 0 ? 36 * 2 : 18;

    let maxHeight = height - (isFullscreen ? 0 : 56 + 45 + padding); // header footer padding
    let maxWidth = width;

    // default values if content is meant to stretch
    let contentWidth = maxWidth - (isFullscreen ? 0 : padding) + "px";
    let contentHeight = maxHeight + "px";

    if (ratio !== 0) {
        contentWidth = maxWidth + "px";
        contentHeight = (maxWidth * (1 / ratio)) + "px";

        // console.log(maxWidth / maxHeight + " " + ratio);

        if (maxWidth / maxHeight > ratio) {
            contentWidth = (maxHeight * ratio) + "px";
            contentHeight = maxHeight;
        }
    }

    // console.log(contentWidth, contentHeight);

    return (
        <div className="wrapper-out">
            <div className="wrapper-in" style={{ width: contentWidth, height: contentHeight }}>
                {children}
            </div>
        </div>
    )
}

export default CanvasWrapper;