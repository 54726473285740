import React from "react";

import './UpdateElement.css';

const UpdateElement = ({ index, date, imgSrc, imgAltText, title, text }) => {
    return (
        <div className="update-element card text-white bg-dark">
            <div className="card-header bg-secondary d-flex flex-row">
                <div className="me-auto">#{index}</div>
                <div className="text-end text-dark">{date}</div>
            </div>

            {imgSrc &&
                <img src={imgSrc} className="card-img-top small-img"
                    alt={imgAltText}>
                </img>
            }

            <div className="card-body bg-secondary">
                <h5 className="card-title basic-outline">{title}</h5>
                <p className="card-text p-2">
                    {text}
                </p>
            </div>
        </div>
    )
}

export default UpdateElement